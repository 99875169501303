import * as React from "react";

import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import logo from "../../assets/images/logos/logo.png";
import {Box, Grid, IconButton} from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import coin from "../../assets/icons/coin-green.svg";
import edit from "../../assets/icons/edit.svg";
import trash from "../../assets/icons/trash.svg";

import {useNavigate} from "react-router-dom";
import {money_fmt, useLoginStore} from "../../services/helpers";
import {ROUTES} from "../../services/constants";

const ProductCard = (
  {
    name,
    description,
    image,
    price,
    stock,
    id,
    is_from_teacher,
    course,
    buyProduct,
    deleteProduct,
  }
) => {
  const navigate = useNavigate();
  const {teacher} = useLoginStore();
  const imageSrc = image ? image : logo;

  const goToDetail = () => {
    navigate(ROUTES.PRODUCT_DETAIL(id), {
      state: {
        id,
        name,
        description,
        image,
        price,
        stock,
        is_from_teacher,
        course
      }
    });
  }

  console.log('course', course)

  return (
    <Card
      sx={{height: "275px", cursor: "pointer", "&:hover": {boxShadow: 4}}}
    >
      <Box
        sx={{
          height: "194px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => goToDetail()}
      >
        <CardMedia
          component="img"
          image={imageSrc}
          alt={name}
          sx={{
            margin: 0,
            objectFit: "contain",
            maxHeight: "194px",
          }}/>
      </Box>

      <CardContent sx={{p: 0,paddingBottom: '0!important', mt:1}}>
        <Grid container
              onClick={() => goToDetail()}
        >
          <Grid item xs={6} pl={2}>
            <Typography
              variant="body2"
              color={(theme) => theme.palette.primary.main}
              fontWeight="bold"
            >
              {name}
            </Typography>
          </Grid>
          <Grid item xs={6} pr={2} justifyContent={'flex-end'} display={'flex'}>
            <Typography
              variant="body2"
              fontWeight="bold"
              display="flex"
              alignItems="center"
              color={(theme) => theme.palette.primary.main}
            >
              {money_fmt(price)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container mt={1}>
          <Grid item xs={6} display={'flex'} justifyContent={'flex-start'} pl={2}>
            {course && <Typography
              variant="body2"
              fontWeight="bold"
              color={(theme) => theme.palette.secondary.main}
            >
              {course.name}
            </Typography>}
          </Grid>
          <Grid item xs={6} display={'flex'} justifyContent={'flex-end'} pr={1}>
            {!teacher && <IconButton onClick={() => buyProduct(id)}>
              <img src={coin} alt="buy" width={20} height={20} title={'Buy Product'}/>
            </IconButton>}
            {is_from_teacher && <>
              <IconButton onClick={() => navigate(ROUTES.PRODUCT_MANAGEMENT(id), {
                state: {
                  id,
                  name,
                  description,
                  image,
                  price,
                  stock,
                  is_from_teacher,
                  course
                }
              })}>
                <img src={edit} alt="edit" width={18} height={18} title={'Edit Product'}/>
              </IconButton>
              <IconButton onClick={() => deleteProduct(id)}>
                <img src={trash} alt="trash" width={20} height={20} title={'Delete Product'}/>
              </IconButton>
            </>}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export const AddProductCard = () => {
  const navigate = useNavigate();


  return (
    <Card
      onClick={() => navigate(ROUTES.CREATE_NEW_PRODUCT)}
      sx={{
        height: "275px",
        cursor: "pointer",
        "&:hover": {boxShadow: 4},
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          height: "100%",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <AddRoundedIcon
          fontSize="100"
          sx={{
            fontSize: "100px",
            color: "rgba(28, 39, 76, 0.25)",
          }}
        />
      </CardContent>
    </Card>
  );
};
export default ProductCard;
