import React, {useEffect, useState} from "react";
import MDTypography from "components/MDTypography";
import {Grid} from "@mui/material";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {count_fmt, money_fmt, useApi, useLoginStore} from "../../services/helpers";
import {useApiMessages} from "../../services/api/api-messages";

import {ROUTES} from "../../services/constants";
import {changeState} from "../../utils/helpers";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";


const ProductDetail = () => {
  const api = useApi();
  const { productId} = useParams()
  const navigate = useNavigate();
  const location = useLocation();
  const {teacher} = useLoginStore();
  const INITIAL_STATE = {
    loading: false,
    data: location.state ? location.state : null,
    deleteModal: false,
    buyModal: false
  }

  const messages = useApiMessages('product', 'products')
  const [pagSt, setPagSt] = useState(INITIAL_STATE);


  const getProductInfo = () => {
    changeState(setPagSt, 'loading', true)
    api.getProductById(productId).handle({
      onSuccess: (res) => {
        setPagSt({...pagSt, data: res.data})
      },
      errorMessage: messages.get.error,
      onError: (err) => {
        console.log('err ', err);
      },
      onFinally: () => changeState(setPagSt, 'loading', false)
    });
  }

  const deleteProduct = () => {
    changeState(setPagSt, 'loading', true)
    api.deleteProduct(productId).handle({
      onSuccess: () => {
        navigate(ROUTES.MARKETPLACE)
      },
      errorMessage: messages.delete.error,
      onError: (err) => {
        console.log('err ', err);
      },
      onFinally: () => changeState(setPagSt, 'loading', false)
    });

  }

  const buyProduct = () => {
    changeState(setPagSt, 'loading', true)
    api.buyProduct(productId).handle({
      onSuccess: () => {
        navigate(ROUTES.MARKETPLACE)
      },
      errorMessage: 'Error buying product',
      successMessage: 'Product bought successfully',
      onError: (err) => {
        console.log('err ', err);
      },
      onFinally: () => changeState(setPagSt, 'loading', false)
    });
  }

  useEffect(() => {
    getProductInfo();
  }, []);

  return (
    <>
      <MDBox display="flex" py={1} borderBottom="1px solid rgba(0, 0, 0, 0.25)" gap={1}>
        <MDTypography flex={1} variant="h3" color="dark" fontWeight="regular">
          Product Details
        </MDTypography>
        <MDButton
          color="white"
          onClick={() =>  navigate(ROUTES.MARKETPLACE)}
        >
          Go Back
        </MDButton>
        {pagSt?.data?.is_from_teacher && <>
          <MDButton
            color="primary"
            disabled={pagSt.loading}
            onClick={() => navigate(ROUTES.PRODUCT_MANAGEMENT(productId), {state: pagSt.data})}
          >
            Edit
          </MDButton>
          <MDButton
            color="error"
            disabled={pagSt.loading}
            onClick={() => changeState(setPagSt, 'deleteModal', true)}
          >
            Delete
          </MDButton>
        </>}
      </MDBox>
      <Grid container>
        <Grid item xs={12} md={6}>
          <MDTypography color="primary" fontSize={{xs: '18px', md: "20px"}} mt={{xs: 1, sm: 3}} mb={2}>
            <b>{pagSt.data ? pagSt.data.name : '-'}</b>
          </MDTypography>
          {pagSt.data?.image && <img src={pagSt.data.image} alt={pagSt.data.name} style={{maxWidth: 300}}/>}
          <MDTypography color="secondary" fontSize={{xs: '18px', md: "20px"}} mt={{xs: 1, sm: 3}}>
            {pagSt.data ? pagSt.data.description : ''}
          </MDTypography>
        </Grid>
        <Grid item xs={12} md={6}>
          {pagSt?.data?.course && <MDTypography color="primary" fontSize={{xs: '18px', md: "20px"}} mt={{xs: 1, sm: 3}}>
            Course: <span style={{color: 'gray', fontWeight: 'bold'}}>{pagSt.data.course.name}</span>
          </MDTypography>}
          <MDTypography color="primary" fontSize={{xs: '18px', md: "20px"}} mt={{xs: 1, sm: 3}}>
            Price: <span style={{color: 'gray', fontWeight: 'bold'}}>{pagSt.data ? money_fmt(pagSt.data.price) : ''}</span>
          </MDTypography>
          {pagSt?.data?.is_from_teacher &&
            <MDTypography color="primary" fontSize={{xs: '18px', md: "20px"}} mt={{xs: 1, sm: 3}}>
              Stock: <span
              style={{color: 'gray', fontWeight: 'bold'}}>{pagSt.data ? count_fmt(pagSt.data.stock) : ''}</span>
            </MDTypography>}
          {!teacher && <MDButton
            color="warning"
            onClick={() => changeState(setPagSt, 'buyModal', true)}
            sx={{mt: 2}}
            disabled={pagSt.loading || pagSt.data.stock === 0}
          >
            Buy Item
          </MDButton>}
        </Grid>
      </Grid>
      <ConfirmModal
        handleClose={() => changeState(setPagSt, 'deleteModal', false)}
        open={pagSt.deleteModal}
        handleConfirm={deleteProduct}
        title={'Delete Product'}
        subtitle={'Are you sure you want to delete this product?'}
      />
      <ConfirmModal
        handleClose={() => changeState(setPagSt, 'buyModal', false)}
        open={pagSt.buyModal}
        handleConfirm={buyProduct}
        title={'Buy Product'}
        subtitle={'Are you sure you want to buy this product?'}
      />
    </>
  );
};

export default ProductDetail;
