import React, {useEffect, useState} from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ProductCard, {AddProductCard} from "./ProductCard";
import {Grid} from "@mui/material";
import Pagination from "../../components/Pagination/Pagination";
import {useApi, useLoginStore} from "../../services/helpers";
import SearchBar from "../../components/SearchBar";
import {changeState} from "../../utils/helpers";
import MDButton from "../../components/MDButton";
import {ROUTES} from "../../services/constants";
import {useApiMessages} from "../../services/api/api-messages";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";

const INITIAL_STATE = {
  counts: 0,
  itemsPerPage: 0,
  currentPage: 1,
  products: [],
  search: '',
  loading: false,
  selectedProduct: null,
  deleteModal: false,
  buyModal: false
}

const MarketplaceHome = () => {
  const api = useApi();
  const {teacher} = useLoginStore();
  const messages = useApiMessages('product', 'products')
  const [pagSt, setPagSt] = useState(INITIAL_STATE);
  const PRODUCTS_PER_PAGE = teacher ? 11 : 12;


  const searchProducts = (search) => {
    changeState(setPagSt,  'search', search)
    getMarketplaceProducts({search: search})
  }

  const getMarketplaceProducts = (searchParams) => {
    changeState(setPagSt, 'loading', true)
    api.getMarketplaceProducts({...searchParams, page_size: PRODUCTS_PER_PAGE}).handle({
      onSuccess: (result) => {
        const {count, results} = result.data
        setPagSt({
          ...pagSt,
          products: results,
          counts: count,
          itemsPerPage: results.length,
          currentPage: searchParams.page || pagSt.currentPage,
        });
      },
      errorMessage: "Error getting courses",
      // onError: (err) => {
      //   console.log('err ', err);
      // }
      onFinally: () => changeState(setPagSt, 'loading', false)
    });
  };

  const deleteProduct = () => {
    changeState(setPagSt, 'loading', true)
    api.deleteProduct(pagSt.selectedProduct).handle({
      onSuccess: () => {
        getMarketplaceProducts({search: ''})
        changeState(setPagSt, 'deleteModal', false)
      },
      successMessage: messages.delete.success,
      errorMessage: messages.delete.error,
      onError: (err) => {
        console.log('err ', err);
      },
      onFinally: () => changeState(setPagSt, 'loading', false)
    });

  }

  const buyProduct = () => {
    changeState(setPagSt, 'loading', true)
    api.buyProduct(pagSt.selectedProduct).handle({
      errorMessage: 'Error buying product',
      onSuccess: () => {
        getMarketplaceProducts({search: ''})
        changeState(setPagSt, 'buyModal', false)
      },
      successMessage: 'Product bought successfully',
      onError: (err) => {
        console.log('err ', err);
      },
      onFinally: () => changeState(setPagSt, 'loading', false)
    });
  }

  const preBuyProduct = (id) => {
    changeState(setPagSt, 'selectedProduct', id)
    changeState(setPagSt, 'buyModal', true)
  }

  const preDeleteProduct = (id) => {
    changeState(setPagSt, 'selectedProduct', id)
    changeState(setPagSt, 'deleteModal', true)
  }


  useEffect(() => {
    getMarketplaceProducts({search: ''});
  }, []);

  const renderItems = (item) => {
    return (
      <Grid key={item.id} item xs={12} sm={6} lg={3} mt={4}>
        <ProductCard
          name={item.name}
          description={item.description}
          image={item.image}
          price={item.price}
          stock={item.stock}
          id={item.id}
          is_from_teacher={item.is_from_teacher}
          course={item.course}
          buyProduct={preBuyProduct}
          deleteProduct={preDeleteProduct}
        />
      </Grid>
    )
  }

  return (
    <>
      <MDBox display="flex" py={1} borderBottom="1px solid rgba(0, 0, 0, 0.25)" gap={3}>
        <MDTypography flex={1} variant="h3" color="dark" fontWeight="regular">
          Marketplace
        </MDTypography>
        <SearchBar
          loading={pagSt.loading}
          setSearchQuery={searchProducts}
        />

        {/*<MDButton variant="contained" onClick={() => {}}>*/}
        {/*  New Category*/}
        {/*</MDButton>*/}
      </MDBox>
      <MDBox px={3}>
        <Grid container mt={3} spacing={{xs: 0, md: 4}}>
          {teacher && <Grid item xs={12} sm={6} lg={3} mt={4}>
            <AddProductCard/>
          </Grid>}
          {pagSt.products.map((item) => renderItems(item))}
        </Grid>
        {pagSt.products.length === 0 &&<MDBox display={'flex'} justifyContent={'center'} alignItems={'center'} height={'500px'}>
          <MDTypography variant={'title'} color={'primary'}>No items found</MDTypography>
        </MDBox>}
      </MDBox>
      <Pagination
        currentPage={pagSt.currentPage}
        totalCount={pagSt.counts}
        pageSize={PRODUCTS_PER_PAGE}
        onPageChange={page => {
          getMarketplaceProducts({...{search: pagSt.search}, page: page})
        }}
      />
      <ConfirmModal
        handleClose={() => changeState(setPagSt, 'deleteModal', false)}
        open={pagSt.deleteModal}
        handleConfirm={deleteProduct}
        title={'Delete Product'}
        subtitle={'Are you sure you want to delete this product?'}
      />
      <ConfirmModal
        handleClose={() => changeState(setPagSt, 'buyModal', false)}
        open={pagSt.buyModal}
        handleConfirm={buyProduct}
        title={'Buy Product'}
        subtitle={'Are you sure you want to buy this product?'}
      />
    </>
  );
};

export default MarketplaceHome;
