// Images

import CoursesList from "./pages/courses";
import Home from "./pages/home";
import home from "./assets/icons/dashboard.svg";
import calendar from "./assets/icons/calendar.svg";
import marketplace from "./assets/icons/marketplace.svg";
import book from "./assets/icons/book.svg";
import chat from "./assets/icons/ChatCircleDots.svg";
import exportIcon from "./assets/icons/export2.svg";
import { ROUTES } from "services/constants";
import Calendar from "pages/calendar-view";
import Marketplace from "pages/marketplace";
import Feedback from "./pages/feedback";
import Export from "./pages/export";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import WBQuestion from "./pages/wb-question";
const routes = [
  {
    type: "collapse",
    name: "Home",
    key: "home",
    route: ROUTES.ROOT,
    teacher: true,
    student: true,
    component: <Home />,
    noCollapse: true,
    icon: <img src={home} alt="home" />,
  },
  {
    type: "collapse",
    name: "Courses",
    key: "courses",
    route: ROUTES.COURSES,
    teacher: true,
    student: true,
    component: <CoursesList />,
    noCollapse: true,
    icon: <img src={book} alt="courses" />,
  },
  {
    type: "collapse",
    name: "Marketplace",
    key: "marketplace",
    route: ROUTES.MARKETPLACE,
    teacher: true,
    student: true,
    component: <Marketplace />,
    noCollapse: true,
    icon: <img src={marketplace} alt="marketplace" />,
  },
  {
    type: "collapse",
    name: "Calendar",
    key: "calendar",
    route: "/calendar",
    teacher: true,
    student: true,
    component: <Calendar />,
    noCollapse: true,
    icon: <img src={calendar} alt="calendar" />,
  },
  {
    type: "collapse",
    name: "Feedback",
    key: "feedback",
    route: "/feedback",
    isModal: true,
    teacher: true,
    student: true,
    component: <Feedback />,
    noCollapse: false,
    icon: <img src={chat} alt="feedback" />,
  },
  {
    type: "collapse",
    name: "Export",
    key: "export",
    route: "/export",
    teacher: true,
    student: false,
    component: <Export />,
    noCollapse: true,
    icon: <img src={exportIcon} alt="export" />,
  },
  {
    type: "collapse",
    name: ["Feedback", "Questions"],
    key: "sw-question",
    route: "/sw-question",
    teacher: true,
    student: false,
    admin: true,
    component: <WBQuestion />,
    noCollapse: true,
    icon: (
      <LocalHospitalIcon
        fontSize={"large"}
        sx={{
          stroke: "#B8CBDD !important",
        }}
      />
    ),
  },
];

export default routes;
