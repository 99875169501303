import * as React from "react";
import PageLayout from "../../components/PageLayout";
import ProductDetail from "./ProductDetail";

const ProductDetailIndex = () => {
  return (
    <PageLayout
      teacherComponent={<ProductDetail />}
      studentComponent={<ProductDetail />}
    />
  );
}


export default ProductDetailIndex;
